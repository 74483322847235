import React from 'react';
import ConfigPage from './pages/ConfigPage';
import MainPage from './pages/MainPage';
import AboutPage from './pages/AboutPage';
import TipsPage from './pages/TipsPage';
import StatusBar from './components/StatusBar';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentScreen: "Home"
    };

    this.onPageSwitch = this.onPageSwitch.bind(this);
    this.onConfigChange = this.onConfigChange.bind(this);
  }

  render() {
    return (
      <div className="App">
        <div className="Header">
        <h1>Taunton Recycling Week</h1>
        <nav className="navbar navbar-expand-lg navbar-light justify-content-center">
          <div className="container-fluid justify-content-center">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#trw-nav" aria-controls="trw-nav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" ></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="trw-nav">
              <div className="navbar-nav">
                <span title="Home" onClick={this.onPageSwitch} className={`nav-link ${this.state.currentScreen === "Home" ? "active" : ""}`}>Home</span>
                <span title="Settings" onClick={this.onPageSwitch} className={`nav-link ${this.state.currentScreen === "Settings" ? "active" : ""}`}>Settings</span>
                <span title="About" onClick={this.onPageSwitch} className={`nav-link ${this.state.currentScreen === "About" ? "active" : ""}`}>About</span>
                <span title="Tips" onClick={this.onPageSwitch} className={`nav-link ${this.state.currentScreen === "Tips" ? "active" : ""}`}>Tips</span>
              </div>
            </div>
          </div>
        </nav>
        </div>
        <div className={`${this.state.currentScreen === "Home" ? "" : "hidden"}`}>
          <MainPage></MainPage>
        </div>
        <div className={`${this.state.currentScreen === "Settings" ? "" : "hidden"}`}>
          <ConfigPage configChangeEvent={this.onConfigChange}></ConfigPage>
        </div>        
        <div className={`${this.state.currentScreen === "About" ? "" : "hidden"}`}>
          <AboutPage></AboutPage>
        </div>
        <div className={`${this.state.currentScreen === "Tips" ? "" : "hidden"}`}>
          <TipsPage></TipsPage>
        </div>
        <StatusBar></StatusBar>
      </div>
    );
  }

  onPageSwitch(event) {
    this.setState({
      currentScreen: event.target.title
    });
  }

  onConfigChange() {
    this.setState({ currentScreen: this.state.currentScreen });
  }
}

export default App;
