import React from 'react';

export default class StatusBar extends React.Component {
    render() {
        if(navigator.onLine){
            return(<div></div>);
        }
        else{
            return (
                <div class="alert alert-success alert-dismissible fade show container" role="alert">
                    <p>This app is running offline.</p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            );
        }
    }
}