import React from 'react';

class StreetTable extends React.Component {

    render() {
        return (
            <div className="container" id="streetTableContainer">
                <table className={`table ${this.props.shown ? "" : "hidden"}`}>
                <thead>
                <tr>
                    <th>Street</th>
                    <th>Name</th>
                    <th>Week</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>                    
                    {this.props.streets.map((obj) => <Street key={"street-"+this.props.streets.indexOf(obj)} street={obj}></Street>)}
                </tbody>
            </table>
            </div>
            
        );
    }
}

class Street extends React.Component{
    render() {
        return (
            <tr>
                    <td>{this.props.street.name}</td>
                    <td>{this.props.street.day}</td>
                    <td>{this.props.street.week}</td>
                    <td>{this.props.street.comment}</td>
            </tr>
        );
    }
}

export default StreetTable;