
/**
 * A class to persist configurable options on the app 
 */
class RecyclingConfiguration{

    static sundayKey = "SundayWeekA";
    static streetKey = "DefaultStreet";

    /**
     * set a new week A sunday
     * 
     * @param {Date} newDate 
     * @returns true if saved successfully, false if not saved
     */
    static setNewSundayWeekA(newDate){
        try{
            let dateParsed = new Date(Date.parse(newDate+"T00:00:00"));
            if(dateParsed.getDay() !== 0){
                throw new Error(`${dateParsed.toLocaleDateString('en-US')} ${dateParsed.getDay()} is not on a sunday(0)`);
            }else{
                localStorage.setItem(this.sundayKey, dateParsed.toString());
                return true;
            }
        }
        catch(e){
            console.log(e);
            return false;
        }
    }

    /**
     * Get a sunday that starts week A
     * 
     * @returns Date
     */
    static getSundayWeekA(){
        if(localStorage.getItem(this.sundayKey) == null){
            localStorage.setItem(this.sundayKey, new Date(2020, 0, 5));
        }
        return new Date(Date.parse(localStorage.getItem(this.sundayKey)));
    }

    /**
     * get the default street saved locally
     * 
     * @returns {String} string
     */
    static getDefaultStreet(){
        let defaultStreet = localStorage.getItem(this.streetKey);
        return defaultStreet === null ? "" : defaultStreet;
    }

    /**
     * save the default street
     *
     * @param {string} street 
     */
    static setDefaultStreet(street){
        localStorage.setItem(this.streetKey, street);
    }
}

export default RecyclingConfiguration;