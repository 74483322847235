import React from "react";

function AboutPage(props) {
    return (
        <div className="container text-start">
            <h2>What is this website?</h2>
            <p>This website is to help the citizens of Taunton know when their recycling day is.
                If someone were to forget their recycling day or week, they would have to find the trash and recycling schedule on the City of Taunton's website and look for their street on the list.
                This website's goal is to find the recycling week by simply typing in the street name.</p>
            <h2>Is this a government website?</h2>
            <p>This website is not made or sponsored by the City of Taunton.</p>
            <h2>Where did you get this information?</h2>
            <p>This information is available on the City of Taunton's <a target="_blank" rel="noopener noreferrer" href="https://www.taunton-ma.gov/recycle">Trash & Recycling Information Page</a>. The <a target="_blank" rel="noopener noreferrer" href="https://www.taunton-ma.gov/sites/g/files/vyhlif1311/f/uploads/taunton_calendar_2021-2022_0.pdf">Trash & Recycling Information PDF</a> shows information such as what week is week A and week B.
                It also has information such as what is recyclable and what is not.
                The <a target="_blank" rel="noopener noreferrer" href="https://www.taunton-ma.gov/sites/g/files/vyhlif1311/f/uploads/city_of_taunton_trash_and_recycle_collection_schedule_4.pdf">Trash & Recycling Schedule by Street PDF</a> shows what week and day a specific street has.</p>
            <h2 id="why">I typed in my street but it says it wasn't found.</h2>
            <p>All the street information on this website was copied from the <a target="_blank" rel="noopener noreferrer" href="https://www.taunton-ma.gov/sites/g/files/vyhlif1311/f/uploads/city_of_taunton_trash_and_recycle_collection_schedule_4.pdf">Trash & Recycling Schedule by Street PDF</a>.
                If your street was not found, here are a couple of reasons why</p>
            <ol>
                <li>The spelling may be incorrect</li>
                <li>How you spell out the street type whether in full(<i>avenue</i>) or its abbreviation(<i>ave</i>)</li>
                <li>The street was not spelled out in full(Typing in <i>Barnum</i> but not Barnum <i>Street or Lane</i>)</li>
            </ol>
            <p>Pressing the "Show Street Table" button before searching your street may help find your street easier.</p>
            <h2>What is the best way to search for my street?</h2>
            <p>Before you type in the street, click on the <b>Show Street Table</b> button.
                This table will display up to 50 out of all the streets the website has.
                As you are typing, the table will shrink, getting to your result faster.</p>
            <h2>The site says that it is week A but it isn't. </h2>
            <p>If the site is incorrect on what week it is, it can be updated in the Settings page located in the menu.</p>
        </div>
    );
}

export default AboutPage;