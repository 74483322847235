import React from "react";

function TipsPage(props){
    return(
        <div className="container text-start">
            <h2>Recycling Bin Tips</h2>
    <ul id="tips">
        <li>For federal holidays, recycling days will be shifted 1 day for that week. 
            So if your street's recycling day is usually Monday, it would be Tuesday for that specific week.</li>
        <li>To ensure that your recycling bin will be picked up, have it out no later than 7AM</li>
        <li>To help make more space in the bin, crush recyclables such as plastics and cardboard before you put them in</li>
        <li>The recycling bins are property of the City of Taunton, so do not deface or paint them</li>
        <li>One recycling bin is given per unit on a street address. Examples:</li>
        <ul>
            <li>A single family home gets one recycling bin</li>
            <li>A multi family home gets one recycling bin per unit/suite/number. (2 units = 2 recycling bins)</li>
        </ul>
        <li>Since a recycling bin is for a street address/unit, do not take the recycling bin if you plan to move</li>
        <li>Have the recycling bin facing out when putting it for recycling day.</li>
        <li>Depending on the cause of damage, you may be eligible for a new cart free of charge. Contact the <a href="https://www.taunton-ma.gov/department-public-works">City of Taunton DPW</a> for more info.</li>
    </ul>
    </div>
    );
}

export default TipsPage;