import React from 'react';
import StreetTable from '../components/StreetTable';
import CurrentDay from '../components/CurrentDay';
import streetDB from '../data/Streets.json'
import '../App.css';
import RecyclingConfiguration from '../persistence/RecyclingConfiguration';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: RecyclingConfiguration.getDefaultStreet(),
      streets: this.filterStreets(RecyclingConfiguration.getDefaultStreet()),
      showTable: false,
      streetInfo: ""
    };

    this.updateSearch = this.updateSearch.bind(this);
    this.onToggleTable = this.onToggleTable.bind(this);
    this.onGetRecyclingInfo = this.onGetRecyclingInfo.bind(this);
    this.spellOutFullStreet = this.spellOutFullStreet.bind(this);
  }

  render() {
    return (
      <div>
        <CurrentDay weekA={RecyclingConfiguration.getSundayWeekA()} />
        <h3>{this.state.streetInfo}</h3>
        <div className="container">
          <div className="row my-2">
            <input className="form-control" onChange={this.updateSearch} type="text" value={this.state.search}></input>
          </div>
          <div className="row justify-content-center my-2">
            <button className="mx-2 p-2 col-4 button" onClick={this.onGetRecyclingInfo}>Get Recycling Info</button>
            <button className="mx-2 p-2 col-4 button" onClick={this.onToggleTable}>{this.state.showTable ? "Hide Street Table" : "Show Street Table"}</button>
          </div>
        </div>
        <StreetTable streets={this.state.streets} shown={this.state.showTable}></StreetTable>
      </div>
    );
  }

  updateSearch(event) {
    this.setState({
      search: event.target.value,
      streets: this.filterStreets(event.target.value),
      showTable: this.state.showTable,
      streetInfo: this.state.streetInfo
    });
  }

  onToggleTable() {
    this.setState({
      search: this.state.search,
      streets: this.state.streets,
      showTable: !this.state.showTable,
      streetInfo: this.state.streetInfo
    });
  }

  onGetRecyclingInfo() {
    let streetRequest = this.spellOutFullStreet(this.state.search).toLowerCase();

    let message = "There was no street found for your input. Check the street table.";
    let streetsFound = streetDB.filter(street => street.name.toLowerCase() === streetRequest);

    switch (streetsFound.length) {
      case 0:
        break;
      case 1:
        let street = streetsFound[0];
        message = `${street.name}'s Recycling Week is on week ${street.week}. The day for recycling is on ${street.day}.`;
        break;
      default:
        message = `${streetsFound.length} street results found from your input. Check out the street table.`;
    }

    this.setState({
      search: this.state.search,
      streets: this.state.streets,
      showTable: this.state.showTable,
      streetInfo: message
    });
  }

  spellOutFullStreet(input) {
    let streetSplit = input.split(" ");
    switch (streetSplit[streetSplit.length - 1].toLowerCase().replace(".", "")) {
      case "str":
      case "st":
        streetSplit[streetSplit.length - 1] = "street";
        break;
      case "ave":
        streetSplit[streetSplit.length - 1] = "avenue";
        break;
      case "rd":
        streetSplit[streetSplit.length - 1] = "road";
        break;
      case "blvd":
        streetSplit[streetSplit.length - 1] = "boulevard";
        break;
      case "dr":
        streetSplit[streetSplit.length - 1] = "drive";
        break;
      default:
        break;
    }
    return streetSplit.join(" ");
  }

  filterStreets(search) {
    let streetArray = [];
    for (let i = 0; i < streetDB.length && streetArray.length < 50; i++) {
      if (streetDB[i].name.toLowerCase().includes(search.toLowerCase())) streetArray.push(streetDB[i]);
    }
    return streetArray;
  }
}

export default MainPage;
