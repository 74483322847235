import React from 'react';
import RecyclingConfiguration from '../persistence/RecyclingConfiguration';

class ConfigPage extends React.Component {

    constructor(props) {
        super(props);
        let currentSunday = RecyclingConfiguration.getSundayWeekA().toISOString().substr(0,10);
        let currentStreet = RecyclingConfiguration.getDefaultStreet();  
        this.state = {
            sunday: currentSunday,
            defaultStreet: currentStreet,
            saveResult: "",
            errorStatus : {
                sunday: this.validateEntry("sunday", currentSunday),
                defaultStreet: this.validateEntry("defaultStreet", currentStreet),
            }
        };

        this.onNewInput = this.onNewInput.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    render() {
        return (
            <div className="container">
                <div className="row m-3">
                    <span className="col-sm-2 col-form-label">Week A Starts:</span>
                    <div className="col-sm-10">
                        <input className="form-control" name="sunday" type="date" value={this.state.sunday} onChange={this.onNewInput}></input>
                    </div>
                    <span className="col-sm-2"></span>
                    <span className="col-sm-10 text-start text-danger">{this.state.errorStatus["sunday"]}</span>
                </div>
                <div className="row m-3">
                    <span className="col-sm-2 col-form-label">Default Street:</span>
                    <div className="col-sm-10">
                        <input className="form-control" name="defaultStreet" value={this.state.defaultStreet} onChange={this.onNewInput}></input>
                    </div>
                    <span className="col-sm-2"></span>
                    <span className="col-sm-10 text-start text-danger">{this.state.errorStatus["defaultStreet"]}</span>
                </div>
                <button className="button p-2" onClick={this.onSave}>Save</button>
                <div className="row m-3">
                    <p>{this.state.saveResult}</p>
                </div>
            </div>
        );
    }

    /**
     * event on new input change
     * @param {HTMLEvent} event 
     */
    onNewInput(event) {
        let updatedProp = event.target.name;
        let updatedValue = event.target.value;
        this.setState({
            [updatedProp]: updatedValue
        });
    }

    onSave() {
        let actionSuccessful = RecyclingConfiguration.setNewSundayWeekA(this.state.sunday);
        RecyclingConfiguration.setDefaultStreet(this.state.defaultStreet);
        if (actionSuccessful) {
            this.setState({ 
                saveResult: "Settings Saved!",
                errorStatus:{
                    sunday: this.validateEntry("sunday", this.state.sunday),
                    defaultStreet: this.validateEntry("defaultStreet", this.state.defaultStreet)
                }
            });
            setTimeout(() => this.setState({ saveResult: "" }), 3000);
            this.props.configChangeEvent();
        }
        else {
            this.setState({ 
                saveResult: "check your input again.",
                errorStatus:{
                    sunday: this.validateEntry("sunday", this.state.sunday),
                    defaultStreet: this.validateEntry("defaultStreet", this.state.defaultStreet)
                }
            });
        }
    }

    /**
     * validate the entry given
     * 
     * @param {*} propName Name of the property validating
     * @param {*} propValue value of the property validating
     * 
     * @returns message of any issues, empty means no issues
     */
    validateEntry(propName, propValue){
        switch(propName){
            case "sunday":
                let entryAsDate = new Date(Date.parse(propValue+"T00:00:00"));
                if(entryAsDate.getDay() !== 0){
                    return "Date is not a sunday";
                }
                return "";
            case "defaultStreet":
                return "";
            default:
                return "";
        }
    }
}

export default ConfigPage;